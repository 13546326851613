import React, { useEffect, useState } from 'react';
import './App.css';

import './assets/fonts/Kanit-Regular.ttf'

type timeLeftType = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

function App() {
  const callForPapersUrl = 'https://sessionize.com/aws-ug-aotearoa-community-day-2023/';
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  })

  return (
    <div className='container'>
      <div className='left-col'></div>
      <div className='center-col'>
        <div className='logo-menu-row'>

          <div className='logo'>
            <img src='cd_logo.png' alt=''></img>
          </div>

          <div className='menu'>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item nav-link">
                <a className="ts-scroll" href="#ts-speakers">SPEAKERS</a>
              </li>
              <li className="nav-item nav-link">
                <a className="ts-scroll" href="#schedules">SCHEDULES</a>
              </li>
              <li className="nav-item nav-link">
                <a className="ts-scroll" href="#sponsors">ORGANISERS</a>
              </li>
            </ul>
            <button className='call-for-papers-button-top' onClick={() => openInNewTab(callForPapersUrl)}>
              CALL FOR SPEAKER</button>
          </div>

        </div>

        <div className='title-text-container'>
          <p>AWS Community Day 2023 - Aotearoa</p>
        </div>

        <div className='down-counter'>
          <section className="countdown">
            <div className="timer">
              <div className="counter">
                <div className="counter__box black-white">
                  <p className="counter__time" id="days"></p>
                  <p className="counter__duration">{timeLeft.days} days</p>
                </div>
                <p className="dots">:</p>
                <div className="counter__box sky-blue">
                  <p className="counter__time" id="hours"></p>
                  <p className="counter__duration">{timeLeft.hours} hours</p>
                </div>
                <p className="dots">:</p>
                <div className="counter__box green">
                  <p className="counter__time" id="minutes"></p>
                  <p className="counter__duration">{timeLeft.minutes} minutes</p>
                </div>
                <p className="dots">:</p>
                <div className="counter__box purple">
                  <p className="counter__time" id="seconds"></p>
                  <p className="counter__duration">{timeLeft.seconds} seconds</p>
                </div>

              </div>
            </div>
          </section>
        </div>

        <div className='body-text'>
          <p>
            The AWS Community Day event is just around the corner and promises to be an exciting opportunity for AWS enthusiasts to come together and learn about the latest developments in cloud computing. With a focus on community-driven content, this event offers attendees the chance to engage with experts in the field, gain valuable insights into best practices, and network with like-minded professionals.
            <br /><br />
            Whether you're a seasoned AWS user or just starting out, there's something for everyone at this event. From technical deep dives to inspirational talks, the sessions are designed to cater to a wide range of interests and skill levels.
            <br /><br />
            So mark your calendars and get ready to join the AWS community on this exciting journey. We can't wait to see you there!
          </p>
        </div>

        <div className='line-break'></div>

        <div className='speaker-title'>
          Call for speaker
        </div>
        <div className='speaker-text'>
          <p>The event thrives on your participation. We look forward to receiving interesting contributions from you via Sessionize, covering AWS topics at a high technical level. If you're wondering why you should participate, check out the reasons below or talk to one of the organisers directly if you're not sure whether your talk fits the concept or not.</p>
        </div>
        <div className='speaker-button'>
          <button className='call-for-papers-button' onClick={() => openInNewTab(callForPapersUrl)}>
            CALL FOR SPEAKER</button>
        </div>
      </div>
      <div className='right-col'></div>
    </div>

  );
}

function calculateTimeLeft() {
  const difference = +new Date("2023-09-20T08:30:00+05:30") - +new Date();
  let timeLeft: timeLeftType = { days: 0, hours: 0, minutes: 0, seconds: 0 };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor(difference / (1000 * 60 * 60)) > 24 ? Math.floor(difference / (1000 * 60 * 60)) - ((Math.floor(difference / (1000 * 60 * 60 * 24))) * 24) : 0,
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export default App;
